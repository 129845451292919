import { AbstractControl } from '@angular/forms';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value1 = control.get(controlName)?.value;
    const value2 = control.get(matchingControlName)?.value;

    if (value1 !== value2) return { matchingFields: true };
    return null;
  };
}

export function MustMatch2(controlName: string, matchingControlName: string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value1 = control.get(controlName)?.value;
    const value2 = control.get(matchingControlName)?.value;
    if (value1 !== value2) return { matchingFields2: true };
    return null;
  };
}
